import {GetStaticPaths, GetStaticPathsResult, GetStaticProps} from 'next'
import {Fragment, useEffect} from 'react'

import {defaultLanguage} from '@/common/config'
import {componentTypes} from '@/common/enums'
import {TStory} from '@/common/types'
import {addNonBreakingSpaces, getAlternativeUrls, getLanguages, notUndefined} from '@/common/utils'
import DynamicComponent from '@/components/DynamicComponent'
import Head from '@/components/Head'
import {
  fetchLinks,
  fetchTranslations,
  getChannels,
  getLinks,
  getTranslations,
} from '@/helpers/fetch-channels'
import {DataFetching} from '@/helpers/fetch-storyblok'
import {GlobalLinksProvider} from '@/hooks/useGlobalLinks'
import {TranslationsProvider} from '@/hooks/useTranslations'

export const getStaticPaths: GetStaticPaths = async () => {
  const FetchMethods = new DataFetching()
  const {isAlreadyCached} = await FetchMethods.createCacheDirectory()
  if (!isAlreadyCached) {
    const languages = await FetchMethods.getLanguageCodes()
    for (const lang of languages) {
      await FetchMethods.getStories(lang)
    }
    await FetchMethods.formatStories()
    await FetchMethods.useAlternativeIndex()
    await fetchTranslations()
    await fetchLinks()
  }

  const nodePaths = await FetchMethods.getPaths()
  const paths: GetStaticPathsResult['paths'] = []
  nodePaths.forEach((path) => {
    const arrayPath = path.split('/')
    const slug = arrayPath.filter((path) => path !== 'index')
    paths.push({params: {slug}})
  })

  return {
    paths: paths,
    fallback: false,
  }
}

export const getStaticProps: GetStaticProps<NonNullable<TStory>> = async (context) => {
  const FetchMethods = new DataFetching()
  if (typeof context.params === 'undefined') throw Error

  let slug
  if (Array.isArray(context.params.slug)) {
    slug = context.params.slug.join('/')
    const isLangIndex = context.params.slug.length === 1
    if (isLangIndex) {
      slug += '/index'
    }
  }

  const story = addNonBreakingSpaces(
    await FetchMethods.getStory(slug || `${defaultLanguage}/index`)
  )

  const channels = await getChannels()
  const translations = await getTranslations()
  const links = await getLinks()
  const isNetlify = process.env.IS_NETLIFY === 'true' ? true : false

  return {props: {...story, channels, translations, links, isNetlify}}
}

const Page = (story: TStory): JSX.Element | null => {
  if (!story.content.body) return null

  const filteredBlocks = story.content.body
    .map((block) => {
      if (block) {
        if (block.component !== componentTypes.GLOBAL_REFERENCE) return block
        if (
          block.reference &&
          block.reference.content &&
          Array.isArray(block.reference.content.body)
        ) {
          return block.reference.content.body[0]
        }
      }
      return
    })
    .filter(notUndefined)

  const {metaDescription, thumbnail} = story.content
  const alternativeUrls = getAlternativeUrls(story)
  const languages = getLanguages(story)

  const extraProps = {
    lightNavigation: story.content.lightNavigation,
    languages,
  }

  useEffect(() => {
    document.querySelectorAll('img').forEach((i) => i.removeAttribute('loading'))
  })

  return (
    <TranslationsProvider translations={story.translations} locale={story.lang}>
      <GlobalLinksProvider lang={story.lang} links={story.links}>
        <Head
          title={story.name}
          alternates={alternativeUrls}
          description={metaDescription}
          thumbnail={thumbnail ? thumbnail.filename : undefined}
          locale={story.lang}
          isNetlify={story.isNetlify}
        />
        <main>
          {filteredBlocks.map((block, index) => {
            if (block.component !== componentTypes.FOOTER) {
              return (
                <Fragment key={block._uid}>
                  <div id={`block-${index + 1}`} />
                  <DynamicComponent
                    {...extraProps}
                    locale={story.lang}
                    resourceCategories={story.resourceCategories}
                    channels={story.channels}
                    {...block}
                  />
                </Fragment>
              )
            }
          })}
        </main>
        <footer>
          {filteredBlocks.map((block) => {
            if (block.component === componentTypes.FOOTER) {
              return <DynamicComponent key={block._uid} {...extraProps} {...block} />
            }
          })}
        </footer>
      </GlobalLinksProvider>
    </TranslationsProvider>
  )
}

export default Page
