// import {action} from '@storybook/addon-actions';
import {action} from '@storybook/addon-actions'
import gsap from 'gsap'
import {capitalize, random} from 'lodash'
import {LoremIpsum} from 'lorem-ipsum'
import {v4 as uid} from 'uuid'

import {defaultLanguage} from '@/common/config'
import {componentTypes, translationNamespaces} from '@/common/enums'
// eslint-disable-next-line prettier/prettier
import {
  TAccordion,
  TAccordionItem,
  TBlok,
  TButton,
  TButtonSecondary,
  TButtonStoryblok,
  TButtonTertiary,
  TCard,
  TCardSlider,
  TCardSliderItem,
  TChannels,
  TChannelSelected,
  TChannelsV2,
  TChannelsV2Card,
  TChannelsV2Cards,
  TChannelsV2Channel,
  TChannelsV2Empty,
  TChannelsV2Filters,
  TCtaCentered,
  TCtaDouble,
  TCtaSimple,
  TCtaWithImage,
  TDraggableCardsList,
  TFeatures,
  TFeaturesAi,
  TFeaturesAiItem,
  TFeaturesItem,
  TFooter,
  TFooterAddress,
  TFooterItem,
  TGlobalLinks,
  TGridCard,
  TGridCards,
  THeroComplex,
  THeroHome,
  THeroSimple,
  THeroWithPartnerLogo,
  TImage,
  TIndustry,
  TIndustryCard,
  TIndustryCards,
  TLanguageSwitcher,
  TLink,
  TLogos,
  TMasonryCard,
  TMasonryCards,
  TNavigation,
  TNavigationItem,
  TReview,
  TScrollingTitle,
  TSlidersButton,
  TSplitWithImage,
  TStoryblokAsset,
  TStoryblokLink,
  TTestimonial,
  TTestimonialItem,
  TTheme,
  TTitle,
  TTitleWithParagraph,
  TTranslations,
  TVerticalSlider,
  TVerticalSliderDots,
  TVerticalSliderItem,
  TVideo,
  TVideoPlayer,
  TWithFullWidthImage,
  TWithImageOverlap,
  TWizard,
} from '@/common/types'

// ------------------------------------------------------------
//  CONFIG
// ------------------------------------------------------------

const internalLinkId = 'my-link'

// ------------------------------------------------------------
//  GENERATE MOCK DATA
// ------------------------------------------------------------

const lorem = new LoremIpsum()
export const generateArray = (min: number, max?: number): unknown[] =>
  Array.from(Array(random(min, max || min)))
// const generateNumberId = () => Number(uid().replace(/\D/g, '').substring(0, 7));
// const generateTitle = () => capitalize(lorem.generateWords(random(4, 10)))
export const generateHeading = (): string => capitalize(lorem.generateWords(random(4, 10)))
export const generateParagraph = (): string => lorem.generateSentences(random(2, 5))
const generateTitle = () => capitalize(lorem.generateWords(random(4, 10)))
const generateLabel = () => capitalize(lorem.generateWords(random(2, 3)))
const generateTag = (): keyof JSX.IntrinsicElements =>
  ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'][random(0, 6)] as keyof JSX.IntrinsicElements
const generateInternalLink = (): TStoryblokLink => ({
  linktype: 'story',
  id: internalLinkId,
})
const generateExternalLink = (): TStoryblokLink => ({
  linktype: 'url',
  url: 'https://www.pelostud.io',
})
const generateAnchorLink = (): TStoryblokLink => ({
  linktype: 'url',
  url: '#myAnchor',
})

const getMockImage = (
  image:
    | 'author'
    | 'author-2'
    | 'meeting-landscape'
    | 'logo-amazon'
    | 'hero-illustration'
    | 'photo.jpeg'
    | 'cta-bg'
    | 'cta-bg-marketplace'
    | 'cta-bg-acquisition'
    | 'masonry-icon'
    | 'with-image-overlap-img'
    | 'accordion'
    | 'living-room-square'
    | 'ebooks'
): TStoryblokAsset => {
  let filename = '/'

  switch (image) {
    case 'author':
      filename += 'author.jpg'
      break
    case 'author-2':
      filename += 'author-2.jpg'
      break
    case 'meeting-landscape':
      filename += 'meeting-landscape.jpg'
      break
    case 'logo-amazon':
      filename += 'logo-amazon.png'
      break
    case 'hero-illustration':
      filename += 'hero-illustration.png'
      break
    case 'masonry-icon':
      filename += 'masonry-icon.png'
      break
    case 'cta-bg':
      filename += 'cta-bg.png'
      break
    case 'cta-bg-marketplace':
      filename += 'cta-bg-marketplace.png'
      break
    case 'cta-bg-acquisition':
      filename += 'cta-bg-Acquisition.png'
      break
    case 'with-image-overlap-img':
      filename += 'with-image-overlap-img.png'
      break
    case 'accordion':
      filename += 'accordion.png'
      break
    case 'living-room-square':
      filename += 'living-room-square.png'
      break
    case 'ebooks':
      filename += 'ebooks.png'
      break
    default:
      filename += 'photo.jpeg'
  }

  return {filename}
}

export const getMockVideo = (): TStoryblokAsset => ({filename: '/video.mp4'})

// ------------------------------------------------------------
//  CONTEXTS
// ------------------------------------------------------------

const translations = {
  [translationNamespaces.CHANNELS_V2_INDUSTRIES]: {
    [defaultLanguage]: generateArray(5, 15).map(() => ({
      name: uid(),
      value: generateLabel(),
    })),
  },
  [translationNamespaces.CHANNELS_V2_CATEGORIES]: {
    [defaultLanguage]: generateArray(3).map(() => ({
      name: uid(),
      value: generateLabel(),
    })),
  },
  [translationNamespaces.CHANNELS_V2_COUNTRIES]: {
    [defaultLanguage]: ['be', 'ca', 'ch', 'de', 'gb', 'it', 'nl', 'us', 'fr', 'es', 'pl'].map(
      (i) => ({name: i, value: generateLabel()})
    ),
  },
  [translationNamespaces.CHANNELS_V2_FEATURES]: {
    [defaultLanguage]: generateArray(3, 6).map(() => ({
      name: uid(),
      value: generateLabel(),
    })),
  },
}

export const getTranslations = (): TTranslations => translations

export const getGlobalLinks = (): TGlobalLinks => ({
  [internalLinkId]: {[defaultLanguage]: 'go-to-my-link'},
})

// ------------------------------------------------------------
//  BUTTON
// ------------------------------------------------------------

export const getButton = ({isLink = false}: {isLink?: boolean}): TButton => {
  return {
    label: generateLabel(),
    onClick: action('onClick'),
    isSmall: false,
    isSecondary: false,
    link: isLink ? generateInternalLink() : undefined,
    isMarketplace: false,
    isAcquisition: false,
  }
}

export const getButtonSecondary = ({isLink = false}: {isLink?: boolean}): TButtonSecondary => {
  return {
    label: generateLabel(),
    onClick: action('onClick'),
    isMarketplace: false,
    hasIcon: false,
    hasRightIcon: false,
    link: isLink ? generateInternalLink() : undefined,
  }
}

export const getButtonTertiary = ({isLink = false}: {isLink?: boolean}): TButtonTertiary => {
  return {
    label: generateLabel(),
    onClick: action('onClick'),
    isMarketplace: false,
    isAcquisition: false,
    link: isLink ? generateInternalLink() : undefined,
    isDark: false,
  }
}

// ------------------------------------------------------------
//  CHANNELS V2
// ------------------------------------------------------------

export const getChannelsV2 = (): TChannelsV2 => {
  return {
    _uid: uid(),
    component: componentTypes.CHANNELS_V2,
    contentHighlightedHeading: generateHeading(),
    contentRegularHeading: generateHeading(),
    contentSearchHeading: generateHeading(),
    filtersMobileOpenButton: generateLabel(),
    filtersMobileSearchButton: `${generateLabel()} __ ${generateLabel()}`,
    filtersDesktopChannelsCount: `__ ${generateLabel()}`,
    filtersSearchHeading: generateLabel(),
    filtersSearchPlaceholder: generateLabel(),
    filtersCategoriesHeading: generateLabel(),
    filtersIndustriesHeading: generateLabel(),
    filtersCountriesHeading: generateLabel(),
    cardsShowMoreLabel: generateLabel(),
    cardsWebsiteLabel: generateLabel(),
    cardsFindOutMoreLabel: generateLabel(),
    noResultsHeading: generateTitle(),
    noResultsDescription: generateParagraph(),
    noResultsButtons: [getButtonStoryblok()],
    locale: 'en',
    channels: {
      en: getChannelsV2Cards().channels || [],
    },
  }
}

const getChannel = (index: number): TChannelsV2Channel => ({
  name: generateLabel(),
  lang: defaultLanguage,
  uuid: uid(),
  content: {
    _uid: uid(),
    categories:
      index % random(4, 6) === 0
        ? translations[translationNamespaces.CHANNELS_V2_CATEGORIES][defaultLanguage].map(
            ({value}) => value
          )
        : index % 2 === 0
        ? [translations[translationNamespaces.CHANNELS_V2_CATEGORIES][defaultLanguage][0].value]
        : [translations[translationNamespaces.CHANNELS_V2_CATEGORIES][defaultLanguage][1].value],
    industries: generateArray(1, 3).map(
      () =>
        translations[translationNamespaces.CHANNELS_V2_INDUSTRIES][defaultLanguage][
          random(
            0,
            translations[translationNamespaces.CHANNELS_V2_INDUSTRIES][defaultLanguage].length - 1
          )
        ].value
    ),
    countries: translations[translationNamespaces.CHANNELS_V2_COUNTRIES][defaultLanguage]
      .slice(random(-5, 5))
      .map(({value}) => value),
    description: generateParagraph(),
    features: translations[translationNamespaces.CHANNELS_V2_FEATURES][defaultLanguage]
      .slice(random(-3, 3))
      .map(({value}) => value),
    logo: getMockImage('logo-amazon'),
    isHighlighted: index % random(8, 10) === 0,
    isFamous: index % random(4, 6) === 0,
    highlightColor: gsap.utils.wrap(['#FF9900', '#BF0000', '#163A8E'], index),
    website: generateExternalLink(),
    findOutMore: generateInternalLink(),
  },
})

export const getChannelsV2Cards = (options?: Partial<TChannelsV2Cards>): TChannelsV2Cards => {
  return {
    heading: generateTitle(),
    channels: generateArray(30, 60).map((_, index) => getChannel(index)),
    ...options,
  }
}

export const getChannelsV2Card = (options?: Partial<TChannelsV2Card>): TChannelsV2Card => {
  return {
    logo: getMockImage('logo-amazon'),
    heading: generateTitle(),
    description: generateParagraph(),
    callToAction: generateLabel(),
    ...options,
  }
}

export const getChannelsV2Filters = (): TChannelsV2Filters => {
  return {
    channels: generateArray(30, 60).map((_, index) => getChannel(index)),
    mobileSearchButton: `${generateLabel()} __ ${generateLabel()}`,
    desktopChannelsCount: `__ ${generateLabel()}`,
    searchHeading: generateLabel(),
    searchPlaceholder: generateLabel(),
    categoriesHeading: generateLabel(),
    industriesHeading: generateLabel(),
    countriesHeading: generateLabel(),
  }
}

export const getChannelsV2Empty = (options?: Partial<TChannelsV2Empty>): TChannelsV2Empty => {
  return {
    heading: generateTitle(),
    description: generateParagraph(),
    buttons: [getButtonStoryblok()],
    ...options,
  }
}

// ------------------------------------------------------------
//  LINK
// ------------------------------------------------------------

export const getLink = (type?: 'external' | 'anchor'): TLink => {
  let link: TStoryblokLink
  switch (type) {
    case 'external':
      link = generateExternalLink()
      break
    case 'anchor':
      link = generateAnchorLink()
      break
    default:
      link = generateInternalLink()
  }

  return {
    label: generateLabel(),
    link,
  }
}

// ------------------------------------------------------------
//  TESTIMONIAL
// ------------------------------------------------------------

export const getTestimonialItem = (even?: boolean): TTestimonialItem => {
  return {
    _uid: uid(),
    component: componentTypes.TESTIMONIAL_ITEM,
    testimonial: lorem.generateSentences(1),
    author: generateLabel(),
    job: generateLabel(),
    picture: getMockImage(even ? 'author-2' : 'author'),
  }
}

export const getTestimonial = (): TTestimonial => {
  return {
    _uid: uid(),
    component: componentTypes.TESTIMONIAL,
    heading: lorem.generateWords(2),
    testimonials: generateArray(5, 8).map((_, i) => getTestimonialItem(i % 2 === 0)),
  }
}

// ------------------------------------------------------------
//  IMAGE
// ------------------------------------------------------------

export const getImage = (
  format: 'square' | 'vertical' | 'horizontal',
  isAlternative = false
): TImage => {
  let size
  switch (format) {
    case 'vertical':
      size = '1200x1800'
      break
    case 'horizontal':
      size = '1800x1200'
      break
    default:
    case 'square':
      size = '2000x2000'
  }

  const imageId = isAlternative ? 'DP0LMBAIzZQ' : 'lVqaovi1Wf0'

  return {
    _uid: uid(),
    component: componentTypes.IMAGE,
    image: {
      className: '',
      filename: `http://source.unsplash.com/${imageId}/${size}`,
    },
    withLoader: false,
  }
}

// ------------------------------------------------------------
//  LOGOS
// ------------------------------------------------------------

export const getLogos = (): TLogos => {
  return {
    _uid: uid(),
    component: componentTypes.LOGOS,
    images: generateArray(8, 14).map((_, i) => getImage('horizontal', i % 2 === 0)),
  }
}

// ------------------------------------------------------------
//  CHANNELS
// ------------------------------------------------------------

export const getChannels = (): TChannels => {
  return {
    _uid: uid(),
    component: componentTypes.CHANNELS,
    heading: generateHeading(),
    paragraph: generateParagraph(),
    cardLarge: generateArray(3, 12).map(() => getCard()),
    cardSmall: generateArray(5, 20).map(() => getCard()),
    btnLabel: generateLabel(),
    btnLabelSecondary: generateLabel(),
    btnLink: generateInternalLink(),
    isMarketplace: false,
    defaultMaxSmallItems: 10,
  }
}

export const getChannelSelected = (): TChannelSelected => {
  return {
    logo: getMockImage('logo-amazon'),
    heading: generateLabel(),
    countries: ['fr', 'gb', 'es', 'de'],
    paragraph: generateParagraph(),
    list: generateArray(3, 8).map(() => generateLabel()),
    btnLabel: generateLabel(),
    btnLabelSecondary: generateLabel(),
    btnLink: generateInternalLink(),
    btnLinkSecondary: generateInternalLink(),
    onClick: action('onClick'),
  }
}

export const getCard = (): TCard => {
  return {
    _uid: uid(),
    component: componentTypes.CARD,
    isLarge: false,
    ...getChannelSelected(),
  }
}

// ------------------------------------------------------------
//  SCROLLING TITLE
// ------------------------------------------------------------

export const getScrollingTitle = (): TScrollingTitle => {
  return {
    _uid: uid(),
    component: componentTypes.SCROLLING_TITLE,
    heading: generateTitle(),
    isDark: false,
    isAcquisition: false,
    isMarketplace: false,
  }
}

// ------------------------------------------------------------
//  VERTICAL SLIDER
// ------------------------------------------------------------

export const getVerticalSlider = (): TVerticalSlider => {
  return {
    _uid: uid(),
    component: componentTypes.VERTICAL_SLIDER,
    isLight: false,
    slides: generateArray(3, 6).map(() => getVerticalSliderItem()),
  }
}

export const getVerticalSliderItem = (): TVerticalSliderItem => {
  return {
    _uid: uid(),
    component: componentTypes.VERTICAL_SLIDER_ITEM,
    index: Math.round(Math.random() * 10),
    heading: generateTitle(),
    paragraph: lorem.generateSentences(2),
    image: getImage('horizontal').image,
    button: [getButtonStoryblok()],
  }
}

export const getVerticalSliderDots = (): TVerticalSliderDots => {
  const length = Math.round(Math.random() * 10)
  const active = random(0, length - 1)
  return {length, active, isLight: false}
}

// ------------------------------------------------------------
//  CARD SLIDER
// ------------------------------------------------------------

export const getCardSlider = (): TCardSlider => {
  return {
    _uid: uid(),
    component: componentTypes.CARD_SLIDER,
    heading: generateLabel(),
    paragraph: lorem.generateSentences(1),
    cards: generateArray(5, 11).map(() => getCardSliderItem()),
    btnLabel: generateLabel(),
    btnLink: generateInternalLink(),
  }
}

export const getCardSliderItem = (): TCardSliderItem => {
  return {
    _uid: uid(),
    component: componentTypes.CARD_SLIDER_ITEM,
    image: getMockImage('meeting-landscape'),
    category: generateLabel(),
    heading: generateTitle(),
    link: generateInternalLink(),
  }
}

// ------------------------------------------------------------
//  SLIDERS BUTTON
// ------------------------------------------------------------

export const getSlidersButton = (): TSlidersButton => {
  return {
    arrow: 'left',
    onClick: action('click'),
    disabled: false,
  }
}

// ------------------------------------------------------------
//  HERO COMPLEX
// ------------------------------------------------------------

export const getHeroComplex = (): THeroComplex => {
  return {
    _uid: uid(),
    component: componentTypes.HERO_COMPLEX,
    heading: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: capitalize(lorem.generateWords(random(2, 4)) + ', '),
              type: 'text',
            },
            {
              text: lorem.generateWords(random(2, 4)) + ' ',

              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              text: lorem.generateWords(random(2, 4)),
              type: 'text',
            },
          ],
        },
      ],
    },
    paragraph: lorem.generateSentences(2),
    secondParagraph: lorem.generateSentences(3),
    images: generateArray(4).map(() => getImage('square')),
    isAlternative: false,
    badgeLink: generateInternalLink(),
  }
}

// ------------------------------------------------------------
//  HERO SIMPLE
// ------------------------------------------------------------

export const getHeroSimple = (options?: {
  heading?: THeroSimple['heading']
  buttons?: {theme?: TTheme}[]
  clipImage?: boolean
  theme?: TTheme
  logos?: TBlok[]
}): THeroSimple => {
  const {buttons} = options || {buttons: [{theme: 'default'}]}

  return {
    _uid: uid(),
    component: componentTypes.HERO_SIMPLE,
    heading:
      options && options.heading
        ? options.heading
        : {
            type: 'doc',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    text: capitalize(lorem.generateWords(random(2, 4)) + ', '),
                    type: 'text',
                  },
                  {
                    text: lorem.generateWords(random(2, 4)) + ' ',
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                  },
                  {
                    text: lorem.generateWords(random(1, 2)) + ' ',
                    type: 'text',
                  },
                  {
                    text: lorem.generateWords(random(2, 3)),
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                      {
                        type: 'italic',
                      },
                    ],
                  },
                ],
              },
            ],
          },
    paragraph: lorem.generateSentences(2),
    image: getMockImage(options?.clipImage ? 'meeting-landscape' : 'hero-illustration'),
    buttons: buttons ? buttons.map((button) => getButtonStoryblok(button.theme)) : undefined,
    theme: options?.theme || 'default',
    clipImage: options?.clipImage || false,
    logos: (options && options.logos) || undefined,
  }
}

// ------------------------------------------------------------
//  INDUSTRY
// ------------------------------------------------------------

export const getIndustryCardsCategory = (): TIndustry => {
  return {
    _uid: uid(),
    component: componentTypes.INDUSTRY_CARDS_CATEGORY,
    heading: lorem.generateWords(1),
    cards: generateArray(5, 8).map(() => getIndustryCard()),
  }
}

// ------------------------------------------------------------
//  INDUSTRY CARDS
// ------------------------------------------------------------

export const getIndustryCards = (): TIndustryCards => {
  return {
    _uid: uid(),
    component: componentTypes.INDUSTRY_CARDS,
    categories: generateArray(2).map(() => getIndustryCardsCategory()),
  }
}

// ------------------------------------------------------------
//  INDUSTRY CARD
// ------------------------------------------------------------

export const getIndustryCard = (): TIndustryCard => {
  return {
    _uid: uid(),
    component: componentTypes.INDUSTRY_CARD,
    heading: capitalize(lorem.generateWords(1)),
    image: getMockImage('living-room-square'),
    link: generateInternalLink(),
  }
}

// ------------------------------------------------------------
//  CTA CENTERED
// ------------------------------------------------------------

export const getCtaCentered = (): TCtaCentered => {
  return {
    _uid: uid(),
    component: componentTypes.CTA_CENTERED,
    heading: generateTitle(),
    paragraph: lorem.generateSentences(3),
    btnLabel: generateLabel(),
    btnLink: generateInternalLink(),
    bgImage: getMockImage('cta-bg'),
  }
}

// ------------------------------------------------------------
//  CTA SIMPLE
// ------------------------------------------------------------

export const getCtaSimple = (): TCtaSimple => {
  return {
    _uid: uid(),
    component: componentTypes.CTA_SIMPLE,
    heading: lorem.generateSentences(2),
    btnLabel: generateLabel(),
    btnLink: generateInternalLink(),
    theme: 'default',
  }
}

// ------------------------------------------------------------
//  CTA WITH IMAGE
// ------------------------------------------------------------

export const getCtaWithImage = (): TCtaWithImage => {
  return {
    _uid: uid(),
    component: componentTypes.CTA_WITH_IMAGE,
    heading: generateHeading(),
    buttons: [getButtonStoryblok()],
    image: getMockImage('ebooks'),
  }
}

// ------------------------------------------------------------
//  CTA DOUBLE
// ------------------------------------------------------------

export const getCtaDouble = (): TCtaDouble => {
  return {
    _uid: uid(),
    component: componentTypes.CTA_DOUBLE,
    headingMarketplace: generateHeading(),
    headingAcquisition: generateHeading(),
    paragraphMarketplace: generateParagraph(),
    paragraphAcquisition: generateParagraph(),
    btnLabelMarketplace: generateLabel(),
    btnLabelAcquisition: generateLabel(),
    btnLinkMarketplace: generateInternalLink(),
    btnLinkAcquisition: generateInternalLink(),
    bgImageMarketplace: getMockImage('cta-bg-marketplace'),
    bgImageAcquisition: getMockImage('cta-bg-acquisition'),
  }
}

// ------------------------------------------------------------
//  MASONRY CARDS
// ------------------------------------------------------------

export const getMasonryCards = (): TMasonryCards => {
  return {
    _uid: uid(),
    component: componentTypes.MASONRY_CARDS,
    subheading: generateTitle(),
    heading: generateTitle(),
    cards: generateArray(4, 9).map(() => getMasonryCard()),
  }
}

export const getMasonryCard = (options?: {withLink: boolean}): TMasonryCard => {
  const {withLink} = options || {}

  return {
    _uid: uid(),
    component: componentTypes.MASONRY_CARD,
    image: getMockImage('masonry-icon'),
    linkLabel: withLink ? generateLabel() : undefined,
    link: withLink ? generateInternalLink() : undefined,
    title: generateTitle(),
    paragraph: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: capitalize(lorem.generateWords(random(3, 6)) + ', '),
              type: 'text',
            },
            {
              text: lorem.generateWords(random(2, 5)) + ' ',

              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              text: lorem.generateWords(random(3, 6)),
              type: 'text',
            },
          ],
        },
      ],
    },
  }
}

// ------------------------------------------------------------
//  DRAGGABLE CARDS LIST
// ------------------------------------------------------------

export const getDraggableCardsList = (options?: {
  number?: number
  withLink?: boolean
}): TDraggableCardsList => {
  const {number, withLink = false} = options || {}

  const generatedArray = number ? generateArray(number) : generateArray(4, 9)
  return {
    _uid: uid(),
    component: componentTypes.DRAGGABLE_CARDS_LIST,
    heading: generateHeading(),
    headingTag: generateTag(),
    cards: generatedArray.map(() => getMasonryCard({withLink})),
  }
}

// ------------------------------------------------------------
//  NAVIGATION
// ------------------------------------------------------------

export const getNavigationItem = (): TNavigationItem => {
  return {
    _uid: uid(),
    component: componentTypes.NAVIGATION_ITEM,
    label: generateLabel(),
    link: generateInternalLink(),
  }
}

export const getNavigation = (): TNavigation => {
  return {
    _uid: uid(),
    component: componentTypes.NAVIGATION,
    items1: generateArray(2).map(() => getNavigationItem()),
    items2: generateArray(3).map(() => getNavigationItem()),
    homeLabel: 'Home label',
    homeLink: generateInternalLink(),
    btnLabel: generateLabel(),
    btnLink: generateInternalLink(),
    lightNavigation: false,
    languages: getLanguageSwitcher().languages,
  }
}

// ------------------------------------------------------------
//  SPLIT WITH IMAGE
// ------------------------------------------------------------

export const getSplitWithImage = (options?: Partial<TSplitWithImage>): TSplitWithImage => {
  return {
    _uid: uid(),
    component: componentTypes.SPLIT_WITH_IMAGE,
    heading: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: capitalize(lorem.generateWords(random(2, 7))),
              type: 'text',
            },
          ],
        },
      ],
    },
    subHeading: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: capitalize(lorem.generateWords(random(2, 4)) + ', '),
              type: 'text',
            },
            {
              text: lorem.generateWords(random(2, 4)) + ' ',

              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              text: lorem.generateWords(random(2, 4)),
              type: 'text',
            },
          ],
        },
      ],
    },
    paragraph: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: lorem.generateSentences(random(2, 3)),
              type: 'text',
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              text: lorem.generateSentences(1) + ' ',

              type: 'text',
              marks: [
                {
                  type: 'italic',
                },
              ],
            },
            {
              text: lorem.generateSentences(1),

              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
          ],
        },
      ],
    },
    btnLabel: generateLabel(),
    btnLink: generateInternalLink(),
    image: getMockImage('hero-illustration'),
    isFlipped: false,
    theme: 'default',
    ...options,
  }
}

// ------------------------------------------------------------
//  WITH IMAGE OVERLAP
// ------------------------------------------------------------

export const getWithImageOverlap = ({
  buttons,
  noScrollingTitle,
  isReversed,
}: {
  buttons: 1 | 2
  noScrollingTitle?: boolean
  isReversed?: boolean
}): TWithImageOverlap => {
  const buttonsMock =
    buttons === 1
      ? [getButtonStoryblok()]
      : [getButtonStoryblok('acquisition'), getButtonStoryblok('marketplace')]

  return {
    _uid: uid(),
    component: componentTypes.WITH_IMAGE_OVERLAP,
    scrollingTitle: noScrollingTitle ? undefined : generateTitle(),
    heading: generateLabel(),
    subheading: generateTitle(),
    firstParagraph: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: capitalize(lorem.generateWords(random(20, 50)) + ', '),
              type: 'text',
            },
            {
              text: lorem.generateWords(random(4, 10)) + ' ',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
          ],
        },
      ],
    },
    secondParagraph: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: capitalize(lorem.generateWords(random(20, 50)) + ', '),
              type: 'text',
            },
            {
              text: lorem.generateWords(random(4, 10)) + ' ',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
          ],
        },
      ],
    },
    image: getMockImage('with-image-overlap-img'),
    buttons: buttonsMock,
    isReversed: isReversed || false,
  }
}

// ------------------------------------------------------------
//  BUTTON STORYBLOK
// ------------------------------------------------------------

export const getButtonStoryblok = (theme?: TTheme): TButtonStoryblok => {
  return {
    _uid: uid(),
    component: componentTypes.BUTTON_STORYBLOK,
    label: generateLabel(),
    link: generateInternalLink(),
    theme: theme || 'default',
  }
}

// ------------------------------------------------------------
//  ACCORDION
// ------------------------------------------------------------

export const getAccordion = (): TAccordion => {
  return {
    _uid: uid(),
    component: componentTypes.ACCORDION,
    heading: generateHeading(),
    items: generateArray(2, 8).map((_, index) =>
      getAccordionItem({isAlternative: index % 2 === 0})
    ),
    isAlternative: false,
  }
}

// ------------------------------------------------------------
//  FOOTER
// ------------------------------------------------------------

export const getFooter = (): TFooter => {
  return {
    _uid: uid(),
    component: componentTypes.FOOTER,
    homeLink: generateInternalLink(),
    addresses: generateArray(2).map(() => getFooterAddress()),
    items: generateArray(8, 12).map(() => getFooterItem()),
    footnote: generateArray(3).map(() => getFooterItem()),
    copyright: `All rights reserved ©Shoppingfeed ${new Date().getFullYear()}`,
    languages: getLanguageSwitcher().languages,
  }
}

export const getFooterAddress = (): TFooterAddress => {
  return {
    _uid: uid(),
    component: componentTypes.FOOTER_ADDRESS,
    title: generateLabel(),
    address: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: lorem.generateWords(random(2, 5)),
              type: 'text',
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              text: lorem.generateWords(random(2, 5)),
              type: 'text',
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              text: lorem.generateWords(random(2, 5)),
              type: 'text',
            },
          ],
        },
      ],
    },
  }
}

export const getFooterItem = (): TFooterItem => {
  return {
    _uid: uid(),
    component: componentTypes.FOOTER_ITEM,
    label: generateLabel(),
    link: generateInternalLink(),
  }
}

// ------------------------------------------------------------
//  HERO HOME
// ------------------------------------------------------------

export const getHeroHome = (): THeroHome => {
  return {
    _uid: uid(),
    component: componentTypes.HERO_HOME,
    heading: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: capitalize(lorem.generateWords(2)),
              type: 'text',
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              text: lorem.generateWords(2) + ' !',

              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
          ],
        },
      ],
    },
    paragraph: lorem.generateSentences(random(2, 3)),
    button: [getButtonStoryblok()],
    image: getMockImage('meeting-landscape'),
  }
}

// ------------------------------------------------------------
//  HERO WITH PARTNER LOGO
// ------------------------------------------------------------

export const geTHeroWithPartnerLogo = (): THeroWithPartnerLogo => {
  return {
    _uid: uid(),
    component: componentTypes.HERO_WITH_PARTNER_LOGO,
    heading: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: capitalize(lorem.generateWords(2) + ' '),
              type: 'text',
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              text: lorem.generateWords(2) + ' !',

              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
          ],
        },
      ],
    },
    paragraph: lorem.generateSentences(random(2, 3)),
    image: getMockImage('living-room-square'),
    logo: getMockImage('logo-amazon'),
  }
}

// ------------------------------------------------------------
//  ACCORDION ITEM
// ------------------------------------------------------------

export const getAccordionItem = (options?: {isAlternative: boolean}): TAccordionItem => {
  const {isAlternative} = options || {}
  return {
    _uid: uid(),
    component: componentTypes.ACCORDION_ITEM,
    heading: generateHeading(),
    paragraph: generateParagraph(),
    btnLabel: generateLabel(),
    btnLink: generateInternalLink(),
    image: getMockImage(isAlternative ? 'author' : 'accordion'),
    isAlternative: false,
    isActive: false,
  }
}

// ------------------------------------------------------------
//  GRID CARDS
// ------------------------------------------------------------

export const getGridCards = (): TGridCards => {
  return {
    _uid: uid(),
    component: componentTypes.GRID_CARDS,
    heading: generateTitle(),
    paragraph: lorem.generateSentences(random(2, 4)),
    button: [getButtonStoryblok()],
    cards: generateArray(6, 10).map(() => getGridCard()),
  }
}

export const getGridCard = (): TGridCard => {
  return {
    _uid: uid(),
    component: componentTypes.GRID_CARD,
    card: [getMasonryCard({withLink: true})],
    images: generateArray(3, 7).map(() => getImage('vertical')),
  }
}

// ------------------------------------------------------------
//  REVIEW
// ------------------------------------------------------------

export const getReview = (): TReview => {
  return {
    _uid: uid(),
    component: componentTypes.REVIEW,
    heading: generateHeading(),
    paragraph: generateParagraph(),
  }
}

// ------------------------------------------------------------
//  TITLE
// ------------------------------------------------------------

export const getTitle = (options?: Partial<TTitle>): TTitle => {
  return {
    _uid: uid(),
    component: componentTypes.TITLE,
    heading: generateTitle(),
    paragraph: lorem.generateSentences(random(2, 4)),
    theme: 'default',
    isCentered: false,
    ...options,
  }
}

// ------------------------------------------------------------
//  LANGUAGE SWITCHER
// ------------------------------------------------------------

export const getLanguageSwitcher = (): TLanguageSwitcher => {
  return {
    languages: [
      {
        label: 'fr',
        value: 'https://google.fr',
        isActive: false,
      },
      {
        label: 'en',
        value: 'https://google.en',
        isActive: false,
      },
      {
        label: 'es',
        value: 'https://google.es',
        isActive: true,
      },
      {
        label: 'it',
        value: 'https://google.it',
        isActive: false,
      },
      {
        label: 'de',
        value: 'https://google.de',
        isActive: false,
      },
      {
        label: 'ja',
        value: 'https://google.ja',
        isActive: false,
      },
    ],
  }
}

// ------------------------------------------------------------
//  VIDEO
// ------------------------------------------------------------

export const getVideo = (): TVideo => {
  return {
    _uid: uid(),
    component: componentTypes.VIDEO,
    thumbnail: getMockImage('meeting-landscape'),
    vimeoId: '640436924',
  }
}

// ------------------------------------------------------------
//  VIDEO PLAYER
// ------------------------------------------------------------

export const getVideoPlayer = (): TVideoPlayer => {
  return {
    vimeoId: '640436924',
  }
}

// ------------------------------------------------------------
//  WITH FULL WIDTH IMAGE
// ------------------------------------------------------------

export const getWithFullWidthImage = (): TWithFullWidthImage => {
  return {
    _uid: uid(),
    component: componentTypes.WITH_FULL_WIDTH_IMAGE,
    paragraphLeft: lorem.generateSentences(random(2, 4)),
    paragraphRight: lorem.generateSentences(random(2, 4)),
    image: getMockImage('with-image-overlap-img'),
  }
}

// ------------------------------------------------------------
//  TITLE WITH PARAGRAPH
// ------------------------------------------------------------

export const getTitleWithParagraph = (): TTitleWithParagraph => {
  return {
    _uid: uid(),
    component: componentTypes.TITLE_WITH_PARAGRAPH,
    subheading: generateTitle(),
    heading: generateTitle(),
    paragraph: generateParagraph(),
    theme: 'default',
  }
}

// ------------------------------------------------------------
//  WIZARD
// ------------------------------------------------------------

export const getWizard = (): TWizard => {
  return {
    _uid: uid(),
    component: componentTypes.WIZARD,
    heading: generateTitle(),
    paragraph: [
      capitalize(lorem.generateWords(random(3, 5))),
      '__',
      lorem.generateWords(random(3, 5)),
      '__.',
    ].join(' '),
    callToAction: generateLabel(),
    categories: generateArray(3, 6).map(() => ({
      _uid: uid(),
      component: componentTypes.WIZARD_OPTION,
      label: generateLabel(),
      marketplaceLink: generateInternalLink(),
      acquisitionLink: generateInternalLink(),
    })),
    locale: defaultLanguage,
  }
}

// ------------------------------------------------------------
//  FEATURES
// ------------------------------------------------------------

export const getFeatures = (): TFeatures => {
  return {
    _uid: uid(),
    component: componentTypes.FEATURES,
    heading: generateHeading(),
    paragraph: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: capitalize(lorem.generateWords(random(3, 5))),
              type: 'text',
            },
            {
              text: '__',
              type: 'text',
              marks: [
                {
                  type: 'italic',
                },
              ],
            },
            {
              text: ' ' + lorem.generateWords(1) + ' ',
              type: 'text',
            },
            {
              text: 'enriching',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              text: ' ' + lorem.generateWords(random(3, 5)),
              type: 'text',
            },
          ],
        },
      ],
    },
    caption: generateParagraph(),
    items: generateArray(3, 10).map((_, i) =>
      getFeaturesItem({image: getMockImage(i % 2 === 0 ? 'photo.jpeg' : 'ebooks')})
    ),
  }
}

export const getFeaturesItem = (props: Partial<TFeaturesItem> = {}): TFeaturesItem => {
  return {
    _uid: uid(),
    component: componentTypes.FEATURES_ITEM,
    label: generateLabel(),
    image: getMockImage('photo.jpeg'),
    ...props,
  }
}

// ------------------------------------------------------------
//  FEATURES AI
// ------------------------------------------------------------

export const getFeaturesAi = (): TFeaturesAi => {
  return {
    _uid: uid(),
    component: componentTypes.FEATURESAI,
    heading: generateHeading(),
    paragraph: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: capitalize(lorem.generateWords(random(3, 5))),
              type: 'text',
            },
            {
              text: '__',
              type: 'text',
              marks: [
                {
                  type: 'italic',
                },
              ],
            },
            {
              text: ' ' + lorem.generateWords(1) + ' ',
              type: 'text',
            },
            {
              text: 'enriching',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              text: ' ' + lorem.generateWords(random(3, 5)),
              type: 'text',
            },
          ],
        },
      ],
    },
    caption: generateParagraph(),
    items: generateArray(3, 10).map((_, i) =>
      getFeaturesItem({image: getMockImage(i % 2 === 0 ? 'photo.jpeg' : 'ebooks')})
    ),
  }
}

export const getFeaturesAiItem = (props: Partial<TFeaturesAiItem> = {}): TFeaturesAiItem => {
  return {
    _uid: uid(),
    component: componentTypes.FEATURESAI_ITEM,
    label: generateLabel(),
    image: getMockImage('photo.jpeg'),
    imageAi: getMockImage('photo.jpeg'),
    ...props,
  }
}
