import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
// import {useEffect, useMemo, useRef} from 'react'
import {useRef} from 'react'

import {LogoNavyInverted as Logo} from '@/assets/specify/'
import {componentTypes} from '@/common/enums'
import {TFooter} from '@/common/types'
import DynamicComponent from '@/components/DynamicComponent'

import LanguageSwitcher from '../LanguageSwitcher'
import Link from '../Link'
import styles from './Footer.module.scss'

gsap.registerPlugin(ScrollTrigger)

const Copyright = ({copyright}: TFooter): JSX.Element | null =>
  copyright ? <p className={styles.copyright}>{copyright}</p> : null

const Footer = (props: TFooter): JSX.Element => {
  const {addresses = [], items, footnote, languages, homeLink} = props

  const sectionRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <section className={styles.section} ref={sectionRef}>
      <div className={styles.container} ref={containerRef}>
        <div className={styles.addresses}>
          <div className={styles.logo}>
            <Link link={homeLink}>
              <Logo title='Home' />
            </Link>
          </div>
          {addresses.map((props) => {
            if (props.component !== componentTypes.FOOTER_ADDRESS) return null
            return (
              <div key={props._uid} className={styles.address}>
                <DynamicComponent {...props} />
              </div>
            )
          })}
        </div>
        {items && (
          <div className={styles.items}>
            {items.map((props) => {
              if (props.component !== componentTypes.FOOTER_ITEM) return null
              return (
                <div className={styles.item} key={props._uid}>
                  <DynamicComponent {...props} />
                </div>
              )
            })}
          </div>
        )}
        <hr className={styles.divider} />
        {footnote && (
          <div className={styles.footnote}>
            {footnote.map((props) => {
              if (props.component !== componentTypes.FOOTER_ITEM) return null
              return (
                <div key={props._uid} className={styles.footnoteItem}>
                  <DynamicComponent {...props} />
                </div>
              )
            })}
            <Copyright {...props} />
          </div>
        )}
        <Copyright {...props} />
        {languages && (
          <div className={styles.languages}>
            <LanguageSwitcher languages={languages} isLight={true} />
          </div>
        )}
      </div>
    </section>
  )
}

export default Footer
