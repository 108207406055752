import React from 'react'
import SbEditable from 'storyblok-react'

import {componentTypes} from '@/common/enums'
import {TBlok} from '@/common/types'
import Accordion from '@/components/Accordion'
import Card from '@/components/Card'
import CardSlider from '@/components/CardSlider'
import CardSliderItem from '@/components/CardSliderItem'
import Channels from '@/components/Channels'
import CtaCentered from '@/components/CtaCentered'
import CtaDouble from '@/components/CtaDouble'
import CtaSimple from '@/components/CtaSimple'
import CtaWithImage from '@/components/CtaWithImage'
import DraggableCardsList from '@/components/DraggableCardsList'
import Features from '@/components/Features'
import FeaturesAi from '@/components/FeaturesAi'
import Footer from '@/components/Footer'
import FooterAddress from '@/components/Footer/FooterAddress'
import FooterItem from '@/components/Footer/FooterItem'
import GridCards from '@/components/GridCards'
import HeroComplex from '@/components/HeroComplex'
import HeroHome from '@/components/HeroHome'
import HeroSimple from '@/components/HeroSimple'
import HeroWithPartnerLogo from '@/components/HeroWithPartnerLogo'
import Image from '@/components/Image'
import IndustryCards from '@/components/IndustryCards'
import IndustryCard from '@/components/IndustryCards/IndustryCard'
import Logos from '@/components/Logos'
import MasonryCard from '@/components/MasonryCard'
import MasonryCards from '@/components/MasonryCards'
import Navigation from '@/components/Navigation'
import Review from '@/components/Review'
import ScrollingTitle from '@/components/ScrollingTitle'
import SplitWithImage from '@/components/SplitWithImage'
import Testimonial from '@/components/Testimonial'
import TestimonialItem from '@/components/TestimonialItem'
import Title from '@/components/Title'
import TitleWithParagraph from '@/components/TitleWithParagraph'
import VerticalSlider from '@/components/VerticalSlider'
import VerticalSliderItem from '@/components/VerticalSliderItem'
import Video from '@/components/Video'
import WithFullWidthImage from '@/components/WithFullWidthImage'
import WithImageOverlap from '@/components/WithImageOverlap'
import Wizard from '@/components/Wizard'

import ChannelsV2 from '../ChannelsV2'

const Component = (props: TBlok): JSX.Element => {
  switch (props.component) {
    case componentTypes.ACCORDION:
      return <Accordion {...props} />
    case componentTypes.CARD:
      return <Card {...props} />
    case componentTypes.CHANNELS:
      return <Channels {...props} />
    case componentTypes.CHANNELS_V2:
      return <ChannelsV2 {...props} />
    case componentTypes.LOGOS:
      return <Logos {...props} />
    case componentTypes.SCROLLING_TITLE:
      return <ScrollingTitle {...props} />
    case componentTypes.TESTIMONIAL:
      return <Testimonial {...props} />
    case componentTypes.TESTIMONIAL_ITEM:
    case componentTypes.TESTIMONIAL_REFERENCE:
      return <TestimonialItem {...props} />
    case componentTypes.VERTICAL_SLIDER_ITEM:
      return <VerticalSliderItem {...props} />
    case componentTypes.VERTICAL_SLIDER:
      return <VerticalSlider {...props} />
    case componentTypes.CARD_SLIDER_ITEM:
      return <CardSliderItem {...props} />
    case componentTypes.CARD_SLIDER:
      return <CardSlider {...props} />
    case componentTypes.CTA_CENTERED:
      return <CtaCentered {...props} />
    case componentTypes.CTA_SIMPLE:
      return <CtaSimple {...props} />
    case componentTypes.CTA_WITH_IMAGE:
      return <CtaWithImage {...props} />
    case componentTypes.CTA_DOUBLE:
      return <CtaDouble {...props} />
    case componentTypes.FEATURES:
      return <Features {...props} />
    case componentTypes.FEATURESAI:
      return <FeaturesAi {...props} />
    case componentTypes.FOOTER:
      return <Footer {...props} />
    case componentTypes.FOOTER_ITEM:
      return <FooterItem {...props} />
    case componentTypes.FOOTER_ADDRESS:
      return <FooterAddress {...props} />
    case componentTypes.GRID_CARDS:
      return <GridCards {...props} />
    case componentTypes.HERO_COMPLEX:
      return <HeroComplex {...props} />
    case componentTypes.HERO_HOME:
      return <HeroHome {...props} />
    case componentTypes.HERO_WITH_PARTNER_LOGO:
      return <HeroWithPartnerLogo {...props} />
    case componentTypes.HERO_SIMPLE:
      return <HeroSimple {...props} />
    case componentTypes.INDUSTRY_CARDS:
      return <IndustryCards {...props} />
    case componentTypes.INDUSTRY_CARD:
      return <IndustryCard {...props} />
    case componentTypes.IMAGE:
      return <Image {...props} />
    case componentTypes.MASONRY_CARD:
      return <MasonryCard {...props} />
    case componentTypes.MASONRY_CARDS:
      return <MasonryCards {...props} />
    case componentTypes.NAVIGATION:
      return <Navigation {...props} />
    case componentTypes.REVIEW:
      return <Review {...props} />
    case componentTypes.SPLIT_WITH_IMAGE:
      return <SplitWithImage {...props} />
    case componentTypes.TITLE:
      return <Title {...props} />
    case componentTypes.DRAGGABLE_CARDS_LIST:
      return <DraggableCardsList {...props} />
    case componentTypes.VIDEO:
      return <Video {...props} />
    case componentTypes.WITH_IMAGE_OVERLAP:
      return <WithImageOverlap {...props} />
    case componentTypes.WITH_FULL_WIDTH_IMAGE:
      return <WithFullWidthImage {...props} />
    case componentTypes.TITLE_WITH_PARAGRAPH:
      return <TitleWithParagraph {...props} />
    case componentTypes.WIZARD:
      return <Wizard {...props} />
    default:
      return <div />
  }
}

const DynamicComponent = (props: TBlok): JSX.Element => {
  return (
    <SbEditable content={props}>
      <Component {...props} />
    </SbEditable>
  )
}

export default DynamicComponent
